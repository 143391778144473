import { api } from "@/constants"
import { get$, post$, patch$ } from "@/requests"
const path = "workplace"

const fetchCompanies = (page = 1, query = "", perPage = 10) => {
  return get$(api.companies.fetch, path, {
    page,
    ...query,
    per_page: perPage
  })
}

const fetchWhitelisted = (page = 1, query = "", perPage = 10) => {
  return get$(api.companies.whitelisted, path, {
    page,
    query,
    per_page: perPage
  })
}

const fetchBlacklisted = (page = 1, query = "", perPage = 10) => {
  return get$(api.companies.blacklisted, path, {
    page,
    query,
    per_page: perPage
  })
}
const fetchPending = (page = 1, query = "", perPage = 10) => {
  return get$(api.companies.pending, path, {
    page,
    query,
    per_page: perPage
  })
}
const fetchSummary = (page = 1, query = "", perPage = 10) => {
  return get$(api.companies.summary, path, {
    page,
    query,
    per_page: perPage
  })
}
const fetchSearch = (page = 1, query = "", perPage = 1) => {
  return get$(api.companies.search, path, {
    page,
    query,
    per_page: perPage
  })
}
const createNewCompany = (form) => {
  return post$(api.companies.addcompany, form)
}
const updateCompanyStatus = (companyId) => {
  return patch$(
    api.companies.companystatus.replace(":companyId", companyId),
    path
  )
}

export {
  fetchWhitelisted,
  fetchCompanies,
  fetchBlacklisted,
  fetchPending,
  fetchSummary,
  fetchSearch,
  createNewCompany,
  updateCompanyStatus
}
